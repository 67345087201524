<template>
    <div class="w-full h-full flex flex-col justify-center items-center rounded-lg bg-white px-2 md:px-5 py-7">
        <img :src="item.image" :alt="item.title" class="w-16 md:w-20" />
        <p class="text-gray-800 font-display leading-tight mt-5 md:mt-8 text-center text-sm md:text-base">{{ item.title }}</p>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: {},
        },
    }
}
</script>