<template>
    <!-- banner -->
    <div
        class="relative lg:flex container pt-3 lg:pt-20 pb-5 md:after:border-r-[35px] md:after:border-l-[35px] md:after:border-t-[35px] md:after:border-transparent md:after:border-t-[#fff8ee] after-content[''] md:after:absolute md:after:left-[10%] md:after:top-full md:after:w-0 md:after:h-0">
        <div class="lg:w-[30%]">
            <h1 class="font-display text-primary text-4xl lg:text-5xl xl:text-[56px] font-bold leading-tight mb-5">Happy
                box in regalo!</h1>
            <p class="font-light text-sm md:text-base text-brand-gray">La gravidanza è un momento speciale: la tua famiglia
                sta per crescere e presto inizierete una nuova avventura! Per questo <span class="italic">Prénatal ti regala
                    Happy Box, il cofanetto ricco di prodotti e servizi per le neo mamme</span> che potrai ritirare in un
                <span class="font-medium">negozio Prénatal a tua scelta</span>.
            </p>
        </div>
        <div class="lg:w-[70%] flex flex-col-reverse md:flex-row mt-8 md:mt-0 items-center lg:items-start md:pt-10 lg:pt-0">
            <div class="relative md:-mr-10 mt-20 md:mt-0 px-7 md:px-0">
                <img src="/images/happy_box.png" alt="Happy box" />
                <img src="/images/free_box.png" alt="Free box"
                    class="absolute -top-16 -right-2 md:left-[2%] w-24 md:w-36" />
            </div>
            <div class="md:w-[60%] flex-shrink-0 z-10 lg:-mt-[19%] md:pl-5">
                <RegistrationAccountInfo />
                <p class="text-brand-gray text-right mt-8 mb-4 hidden md:block">L’immagine è a puro scopo illustrativo, i
                    prodotti cambiano periodicamente, a seconda del periodo e luogo di ritiro</p>
            </div>
        </div>
        <p class="text-brand-gray text-sm text-right mt-5 md:hidden">L’immagine è a puro scopo illustrativo, i prodotti cambiano
            periodicamente, a seconda del periodo e luogo di ritiro</p>
    </div>

    <!-- contents -->
    <HomeHappyBoxContents />

    <div class="bg-[#FFF8EE]">
        <!-- videos -->
        <HomeVideos />

        <!-- reviews -->
        <HomeReviews />

        <!-- cta -->
        <div
            class="container flex flex-col md:flex-row items-center md:space-x-10 xl:w-1/2 justify-center pt-5 pb-8 md:py-20">
            <p class="font-display text-primary text-xl md:text-2xl font-medium mb-6 md:mb-0">Vuoi veicolare i tuoi prodotti
                o sconti aziendali dentro Happy box?</p>
            <CoreButtonLink class="flex-shrink-0 px-16 w-full md:w-fit" href="/request-partner">Diventa Partner</CoreButtonLink>
        </div>

        <!-- logos -->
        <HomeClients />
    </div>

    <!-- partners -->
    <HomePartners />
</template>

<script>
export default {
    mounted() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'VirtualPage',
            'VirtualPageName': 'Anagrafica',
            'VirtualPageTitle': 'Richiedi la tua Happy Box',
            'VirtualPageStep': '1'
        });
    },
}
</script>