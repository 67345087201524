<template>
    <div class="flex space-x-4 items-center p-5">
        <img src="/icons/warning.svg" />
        <span class="text-red-600 font-bold text-sm">{{ error }}</span>
    </div>
</template>

<script>
export default {
    props: {
        error: {
            type: String,
            default: ''
        }
    }
}
</script>