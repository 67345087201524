<template>
    <div class="bg-white rounded-3xl shadow-md py-8 md:py-12 px-7 md:px-10">
        <p class="font-display text-primary text-lg lg:text-2xl xl:text-3xl font-bold mb-2 md:mb-7">Richiedi la tua Happy
            Box!</p>
        <p class="text-sm md:text-base text-brand-gray mb-5 md:mb-10">Inizia la richiesta inserendo la tua email, se non hai
            già la Prénatal Card, ti chiederemo qualche dato aggiuntivo.</p>
        <CoreInput id="email" v-model="email" placeholder="Email *" required />
        <CoreInputError :error="useGet(errors, 'email')" />
        <CoreButton :class="{
            'opacity-70': loading
        }" 
        v-bind:disabled="loading"
        class="w-full mt-6 md:mt-10" @click="verifyEmail">Richiedila subito, è gratis</CoreButton> 
        <CoreError v-if="error" :error="error"></CoreError>
    </div>
</template>

<script>
import * as yup from 'yup';

const schema = yup.string().trim().email().label('Email').required('Email è obbligatorio');

export default {
    data() {
        return {
            email: undefined,
            errors: {},
            error: '',
            loading: false,
        }
    },
    mounted() {
        const emailCookie = useCookie('email');
        this.email = emailCookie.value ?? this.email;
    },
    methods: {
        async verifyEmail() {
            try {
                await schema.validate(this.email);
            } catch (error) {
                if (error.message) {
                    this.errors.email = error.message;
                }
                return;
            }

            this.loading = true
            await $fetch('/verify-email', {
                method: 'post',
                body: {
                    email: this.email
                }
            }).then(response => {
                this.loading = false;
                this.error = '';
                // set cookie with 30 minutes expiration time for email and user id
                const emailCookie = useCookie('email', {
                    expires: new Date((new Date()).getTime() + 30*60000)
                });

                if (response.user_id) {
                    const userIdCookie = useCookie('user_id', {
                        expires: new Date((new Date()).getTime() + 30*60000)
                    });
                    userIdCookie.value = response.user_id;
                }

                //go to HappyBox registration
                emailCookie.value = this.email;
                const route = useRoute();
                this.$router.push(`/registration-card${route.fullPath}`);
            }).catch(error => {
                this.loading = false;
                this.error = error.response?._data.message ?? 'È successo qualcosa, riprova più tardi';
            });
        }
    }
}
</script>