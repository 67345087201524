<template>
    <div class="container pt-10 pb-20">
        <p class="text-primary text-md text-center font-display font-semibold uppercase mb-10">In questi mesi hanno
            collaborato
            con noi:</p>
        <div class="flex flex-wrap items-center justify-center space-y-5">
            <img v-for="logo in logos" :src="logo.url" :alt="logo.alt"
                class="w-1/2 sm:w-1/3 lg:w-1/4 xl:w-1/5 px-5 md:px-10 max-h-24 object-contain"  />
        </div>
    </div>
</template>

<script setup>
const logos = [
    {
        alt: 'Huggies',
        url: '/images/huggies.png',
    },
    {
        alt: 'Mam',
        url: '/images/mam.png',
    },
    {
        alt: 'Fiocchi di riso',
        url: '/images/fiocchidiriso_logo.png',
    },
    {
        alt: 'Aptamil',
        url: '/images/aptamil.png',
    },
    {
        alt: 'Photo si',
        url: '/images/photosi.png',
    },
    {
        alt: 'The pee & the poo',
        url: '/images/peepoo.png',
    },
    {
        alt: 'Chicco',
        url: '/images/chicco.png',
    },
    {
        alt: 'Mustela',
        url: '/images/mustela_logo.png',
    },
    {
        alt: 'Tippy',
        url: '/images/tippy.png',
    },
    {
        alt: 'Progetto Salute',
        url: '/images/progettosalute.png',
    },
    {
        alt: 'Natalben',
        url: '/images/natalben.png',
    },
    {
        alt: 'Water pipes',
        url: '/images/waterwipes.png',
    },
    {
        alt: 'Lichtena',
        url: '/images/lichtena.png',
    },
    {
        alt: 'Bio presto',
        url: '/images/biopresto.png',
    },
    {
        alt: 'Hello fresh',
        url: '/images/hellofresh.png',
    },
];
</script>