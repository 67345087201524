<template>
    <div style="background-image: url('/images/background.png');" class="bg-no-repeat bg-cover md:bg-contain bg-center">
        <div class="container pt-16 md:py-20">
            <h3 class="font-display text-primary text-2xl md:text-3xl lg:text-[40px] font-semibold mb-8 md:mb-12">Le opinioni delle mamme
            </h3>
            <div class="-mx-2 pb-10">
                <Swiper
                    :modules="[SwiperPagination]"
                    :slidesPerView="1"
                    :spaceBetween="25"
                    :pagination="{
                        el: '.swiper-pagination'
                    }"
                    :breakpoints="{
                            768: {
                                slidesPerView: 3
                            },
                        }"
                    >
                    <SwiperSlide v-for="review in reviews">
                        <div class="p-2">
                            <div class="bg-white rounded-2xl px-10 py-7 shadow-md">
                                <p class="text-primary text-lg font-semibold">{{ review.author }}</p>
                                <div class="whitespace-nowrap flex mb-3 md:my-3" v-if="reviews.stars">
                                    <img v-for="i in review.stars" src="/icons/star.svg" class="w-4 h-4" />
                                </div>
                                <p class="text-brand-gray font-light">{{ review.description }}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <div class="swiper-pagination mt-4 w-full relative"></div>
                </Swiper>
            </div>
        </div>
    </div>
</template>

<script setup>
    //replace with reviews from source
    const reviews = [
        {
           author: 'Luisa, Bergamo',
           stars: 0,
           description: 'Una happy box di nome e di fatto. Veramente molto soddisfatta. Non vedo l\'ora di provare tutti i doni con il mio bambino/a'
        },
        {
           author: 'Arianna, Monza',
           stars: 0,
           description: 'Il contenuto è stato davvero una sorpresa. Elementi essenziali e di qualità. Ottima iniziativa'
        },
        {
           author: 'Giovanna, Pavia',
           stars: 0,
           description: 'Bellissima box con molti prodotti utili e che sicuramente andrò ad utilizzare appena nascerà il mio piccolo'
        },
        {
           author: 'Michela, Roma',
           stars: 0,
           description: 'Una box ricca di coupon adatti a tutta la famiglia e anche per chi tra poco arriverà.. molto utile e interessante grazie per averla creata e pensato a noi e a questo momento così magico della vita'
        },
        {
           author: 'Assunta, Palermo',
           stars: 0,
           description: 'È veramente stupenda e molto utile. Una happy box pensata molto bene per far provare vari prodotti per neonati ❤️ 🥰la adoro🥰🥰'
        },
        {
           author: 'Maria Grazia, Reggio Calabria',
           stars: 0,
           description: 'La Happy Box è molto carina e funzionale. Buoni sconto e campioni omaggio che potrò provare quando nascerà la mia bambina. Il mio pezzo preferito sono le calze che non vedo l\'ora di farle indossare. Tutto molto apprezzato.'
        },
    ];
</script>