<template>
    <div class="p-3">
        <div class="relative">
            <video class="w-full h-auto rounded-2xl" loop muted preload="auto" playsinline ref="videoRef" controls>
                <source :src="item.src" type="video/mp4">
                <source :src="item.src" type="video/webm">
                <source :src="item.src" type="video/ogv">
                Your browser does not support the video tag.
            </video>
            <div class="absolute inset-0 w-full h-full cursor-pointer" v-if="showImage" v-on:click="playVideo">
                <NuxtImg :src="item.image" class="w-full h-full object-cover rounded-2xl" />
                <img src="/icons/play.svg" class="absolute inset-0 m-auto" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object
        },
    },
    setup() {
        const videoRef = ref(null)
    },
    data() {
        return {
            showImage: true,

        }
    },
    methods: {
        playVideo() {
            this.showImage = false;
            document.querySelectorAll('video').forEach(video => video.pause());
            this.$refs.videoRef?.play()
        }
    }
}
</script>