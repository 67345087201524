<template>
    <div class="bg-[#EFE6D9]">
        <div class="container py-16 md:py-20">
            <p class="text-primary text-lg font-display font-medium">Ci supportano nella produzione di Happy box:</p>
            <div class="flex flex-col lg:flex-row lg:space-x-10 items-center mt-8">
                <div class="flex space-x-5 flex-shrink-0 flex-wrap justify-center md:justidy-start">
                    <div class="bg-white rounded-full px-4 md:px-5 py-3 md:py-4 flex items-center justify-center">
                        <img src="/images/social-innovation.png" alt="social innovation" class="h-8 md:h-16" />
                    </div>
                    <div class="bg-white rounded-full flex items-center px-5 md:px-10 flex-shrink-0">
                        <img src="/images/tenda-matese.png" alt="tenda matese" class="h-10 md:h-12" />
                    </div>
                    <div class="bg-white rounded-full flex items-center px-7 md:px-10 py-3 md:py-0 flex-shrink-0 mt-5 md:mt-0">
                        <img src="/images/csl.png" alt="csl" class="h-10 md:h-14" />
                    </div>
                </div>
                <p class="text-brand-gray font-thin lg:pl-10 text-sm md:text-base mt-10 lg:mt-0">Promuoviamo l’inserimento e l’integrazione lavorativa di 5
                    persone che, con cura e amore, ogni giorno confezionano le box distribuite</p>
            </div>
        </div>
    </div>
</template>